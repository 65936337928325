.how-to-play-links-container {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 650px;
    margin: 0 auto;
}

.how-to-play-title {
    color: #333;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
}

.how-to-play-table-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 8px;
}

.how-to-play-column {
    flex: 1;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.how-to-play-nav-link {
    color: black;
    text-decoration: none;
    font-size: 18px;
    padding: 10px;
    border: 1px solid black;
    border-radius: 4px;
    text-align: center;
    transition: background-color 0.3s, color 0.3s;
}

.how-to-play-nav-link:hover {
    background-color: grey;
    color: #fff;
}
