.comment-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.comment-form-wrapper {
    width: 90%;
    max-width: 800px;
    padding: 40px;
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.comment-form-label {
    text-align: center;
    margin-bottom: 20px;
    font-size: 28px;
    color: #333;
}

.comment-form-input {
    width: 100%;
    padding: 15px;
    font-size: 16px;
    color: #555;
    margin-bottom: 20px;
    border-radius: 5px;
    border: 1px solid #ced4da;
}

.comment-form-input::placeholder {
    color: #adb5bd;
}

.comment-form-error {
    color: red;
    float: right;
}

.comment-form-submit {
    width: 100%;
    padding: 15px;
    font-size: 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.comment-form-submit:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
}

@media (max-width: 768px) {
    .comment-form-wrapper {
        padding: 20px;
    }

    .comment-form-label {
        font-size: 24px;
    }
}