.tile {
    width: 100px;
    height: 100px;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    border-radius: 5px;
    background-color: #eee4da;
    color: #776e65;
    align-items: center;
    justify-content: center;
}

.tile.value-0 { background-color: #faf8ef; color: #776e65; }
.tile.value-1 { background-color: #eee4da; color: #776e65; }
.tile.value-2 { background-color: #ccc0b3; color: #776e65; }
.tile.value-4 { background-color: #ede0c8; color: #776e65; }
.tile.value-8 { background-color: #f2b179; color: #f9f6f2; }
.tile.value-16 { background-color: #f59563; color: #f9f6f2; }
.tile.value-32 { background-color: #f67c5f; color: #f9f6f2; }
.tile.value-64 { background-color: #f65e3b; color: #f9f6f2; }
.tile.value-128 { background-color: #edcf72; color: #f9f6f2; }
.tile.value-256 { background-color: #edcc61; color: #f9f6f2; }
.tile.value-512 { background-color: #edc850; color: #f9f6f2; }
.tile.value-1024 { background-color: #edc53f; color: #f9f6f2; }
.tile.value-2048 { background-color: #edc22e; color: #f9f6f2; }

.tile.appear {
    animation-name: appear;
    animation-duration: 0.3s;
}

@keyframes appear {
    from { transform: scale(0); }
    to { transform: scale(1); }
}

.tile.small {
    width: 80px;
    height: 80px;
    font-size: 20px;
}

.tile.medium {
    width: 90px;
    height: 90px;
    font-size: 22px;
}

.tile.large {
    width: 100px;
    height: 100px;
    font-size: 24px;
}