.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.form-wrapper {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    width: 400px;
}

h1 {
    margin-bottom: 20px;
    font-size: 36px;
    color: #333333;
    text-align: center;
}

.form-group {
    margin-bottom: 20px;
    text-align: left;
}

.form-label {
    font-weight: bold;
    text-align: left;
}

.form-control {
    width: 100%;
}

.submit-btn {
    width: 100%;
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-btn:hover {
    background-color: #45a049;
}

.registration-link {
    text-align: left;
}

.submit-btn:disabled {
    background-color: lightgrey;
    opacity: 0.65;
}