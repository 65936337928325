.main-container {
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-family: Arial, sans-serif;
    max-width: 650px;
    margin: 70px auto;
}

.main-content {
    width: 100%;
}

.main-header {
    text-align: center;
}

.main-description {
    text-align: center;
    margin-bottom: 20px;
}

.main-time {
    text-align: center;
    margin-bottom: 20px;
}

.main-game-info {
    margin-bottom: 20px;
}

.main-resources {
    text-align: center;
}

.main-user-info {
    text-align: center;
}

.main-user-info p {
    margin-bottom: 10px;
}

.main-user-info button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.main-user-info button:hover {
    background-color: #0056b3;
}