.field {
    border-collapse: collapse;
    margin: 40px auto;
    background-color: #f0f0f0;
}

.field td {
    border: 4px solid #999;
    padding: 10px;
    text-align: center;
}

.field td:hover {
    background-color: lightgrey;
}

.field td span {
    display: block;
    font-size: 24px;
    font-weight: bold;
}