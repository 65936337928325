.game-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.game-over-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    color: white;
    font-size: 36px;
    text-align: center;
}

@keyframes moveText {
    0% {
        transform: translateY(20px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes moveEmoji {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    50% {
        transform: scale(1.2);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes rotateEmoji {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.game-table {
    width: 600px;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.game-table td {
    padding: 10px;
    border: 1px solid #ccc;
}

.game-table tr:last-child td {
    border-bottom: none;
}

.game-table tr:first-child td {
    border-top: none;
}

.game-table tr td:first-child {
    width: 50%;
    text-align: left;
}

.game-table tr td:last-child {
    width: 50%;
    text-align: right;
}

.game-table h3 {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
}

.game {
    background-color: #f0f0f0;
    padding: 40px;
    border-radius: 20px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    width: 600px;
    margin-bottom: 20px;
}

.login-text {
    color: #555;
    font-style: italic;
    font-size: 18px;
}

.login-container {
    display: flex;
    align-items: center;
}