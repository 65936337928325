.videos {
    background: linear-gradient(white, #ffdab9);
}

.videos-container {
    max-width: 650px;
    margin: 70px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.videos-container h2 {
    margin-top: 0;
}

.videos-container p {
    margin-top: 10px;
    margin-bottom: 32px;
}

.videos-container ul {
    list-style: none;
    padding: 0;
    text-align: center;
}

.videos-container li {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}


.video-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    width: 100%;
    height: 300px;
}

.videos-container a {
    text-decoration: none;
}

.videos-container a:hover {
    text-decoration: underline;
}

.video-wrapper h3 {
    text-align: left;
    font-size: 20px;
}