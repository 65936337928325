.variations-container {
    max-width: 650px;
    margin: 70px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.variations-container h2 {
    margin-top: 0;
}

.variations-container p {
    margin-top: 10px;
    margin-bottom: 20px;
}

.variations-container ul {
    list-style: none;
    padding: 0;
}

.variations-container li {
    margin-bottom: 10px;
}