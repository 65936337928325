.qna-container {
    max-width: 650px;
    margin: 70px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.qna-container h2 {
    margin-top: 0;
}

.question {
    margin-bottom: 20px;
}

.question p {
    margin: 10px 0;
    line-height: 1.5;
}

.question span {
    margin-right: 5px;
}

.question span[role="img"] {
    font-size: 20px;
}

.question span[role="img"]:last-child {
    margin-right: 0;
}

.question p:first-child {
    font-weight: bold;
}