.tips-container {
    max-width: 650px;
    margin: 70px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.tips-container h2 {
    margin-top: 0;
}

.tips-section {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    padding: 0.5em;
}
