.scores-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.scores-wrapper {
    width: 90%;
    max-width: 800px;
    padding: 40px;
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.scores-header {
    text-align: center;
    margin-bottom: 20px;
    font-size: 28px;
    color: #333;
}

.scores-table {
    width: 100%;
}

.scores-table th,
.scores-table td {
    text-align: center;
    padding: 15px;
    font-size: 16px;
    color: #555;
}

.scores-table thead {
    background-color: #343a40;
    color: white;
}

.scores-table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
}

@media (max-width: 768px) {
    .scores-wrapper {
        padding: 20px;
    }

    .scores-header {
        font-size: 24px;
    }
}