.quotes-container {
    max-width: 650px;
    margin: 70px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.quotes-container h2 {
    margin-top: 0;
}

.quote {
    margin-top: 20px;
}

.quote p {
    margin: 0;
    font-style: italic;
}